var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mt-4 navbar__main " },
    [
      _c(
        "b-row",
        { staticClass: "p-3" },
        [
          _c(
            "b-col",
            {
              staticClass: "pr-0",
              attrs: { cols: _vm.$i18n.locale === "es" ? 3 : 4, width: "100px" }
            },
            [
              _c(
                "b-row",
                { staticClass: "align-items-center mb-3" },
                [
                  _c("span", { staticClass: "font__heavy" }, [
                    _vm._v(_vm._s(_vm.$t("navbarInfo.sedeOrigen")) + ":")
                  ]),
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        id: "dropdown-1",
                        size: "sm",
                        text: _vm.sedeOrigen,
                        variant: "outline-white",
                        disabled: _vm.pathName === "Recipe"
                      }
                    },
                    [
                      _vm.country === "US"
                        ? [
                            _c("div", { staticClass: "header__dropdown" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../assets/images/eeuu-flag.svg"),
                                  alt: ""
                                }
                              }),
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("navbarInfo.USA")))
                              ])
                            ]),
                            _vm._l(_vm.origenUS, function(item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "item__dropdown" },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { "data-name": item },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickSede(item, "origen")
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                ],
                                1
                              )
                            })
                          ]
                        : [
                            _c("div", { staticClass: "header__dropdown" }, [
                              _c("img", {
                                attrs: {
                                  src: require("../assets/images/mexico-flag.svg"),
                                  alt: ""
                                }
                              }),
                              _c("b", [_vm._v("México")])
                            ]),
                            _vm._l(_vm.origenMX, function(item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + 5,
                                  staticClass: "item__dropdown"
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: { "data-name": item },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickSede(item, "origen")
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(item.label) + " ")]
                                  )
                                ],
                                1
                              )
                            })
                          ]
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "align-items-center mb-3" },
                [
                  _c("span", { staticClass: "font__heavy" }, [
                    _vm._v(_vm._s(_vm.$t("navbarInfo.sedeDestino")) + ":")
                  ]),
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        id: "dropdown-2",
                        size: "sm",
                        text: _vm.sedeDestino,
                        variant: "outline-white"
                      }
                    },
                    [
                      _c("div", { staticClass: "header__dropdown" }, [
                        _c("img", {
                          attrs: {
                            src: require("../assets/images/eeuu-flag.svg"),
                            alt: ""
                          }
                        }),
                        _c("b", [_vm._v(_vm._s(_vm.$t("navbarInfo.USA")))])
                      ]),
                      _vm._l(_vm.destinoUS, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "item__dropdown" },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { "data-name": item },
                                on: {
                                  click: function($event) {
                                    return _vm.clickSede(item, "destino")
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      _c("b-dropdown-divider"),
                      _c("div", { staticClass: "header__dropdown" }, [
                        _c("img", {
                          attrs: {
                            src: require("../assets/images/mexico-flag.svg"),
                            alt: ""
                          }
                        }),
                        _c("b", [_vm._v("México")])
                      ]),
                      _vm._l(_vm.destinoMX, function(item, index) {
                        return _c(
                          "div",
                          { key: index + 5, staticClass: "item__dropdown" },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { "data-name": item },
                                on: {
                                  click: function($event) {
                                    return _vm.clickSede(item, "destino")
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      _c("div")
                    ],
                    2
                  )
                ],
                1
              ),
              _c("b-row", { staticClass: "align-items-center mb-3" }, [
                _c("b", { staticClass: "font__heavy pr-2" })
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: _vm.$i18n.locale === "es" ? 3 : 2 } },
            [
              _c(
                "b-form",
                [
                  _c("b-row", { staticClass: "align-items-center mb-3" }, [
                    _c("span", { staticClass: "font__heavy pr-2" }, [
                      _vm._v(_vm._s(_vm.$t("navbarInfo.patientName")) + ":")
                    ]),
                    _vm._v(" " + _vm._s(_vm.nombrePaciente) + " ")
                  ]),
                  _c("b-row", { staticClass: "align-items-center mb-3" }, [
                    _c("b", { staticClass: "font__heavy pr-2" }, [
                      _vm._v("NHC:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.NHC) + " ")
                  ]),
                  _c("b-row", { staticClass: "align-items-center mb-3" }, [
                    _c("b", { staticClass: " font__heavy pr-2" }, [
                      _vm._v(_vm._s(_vm.$t("navbarInfo.labelperfil")) + ":")
                    ]),
                    _c("span", { staticClass: "emoji" }, [
                      _vm._v(
                        _vm._s(_vm.score_azul_verde) +
                          " " +
                          _vm._s(_vm.score_socioeconomico_mx)
                      )
                    ]),
                    _vm._v(" " + _vm._s(_vm.score_alto___bajo) + " ")
                  ]),
                  _c("b-row", { staticClass: "align-items-center mb-3" }, [
                    _c("b", { staticClass: "font__heavy pr-2" }, [
                      _vm._v(_vm._s(_vm.$t("navbarInfo.labelsubtipo")) + ":")
                    ]),
                    _vm._v(" " + _vm._s(_vm.subtipo) + " ")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "2" } },
            [
              _c(
                "b-row",
                { staticClass: "align-items-center mb-3" },
                [
                  _c("b-col", [
                    _c("b", { staticClass: "font__heavy pr-2" }, [
                      _vm._v("Estatus referido:")
                    ]),
                    _c("b", { staticClass: "text-primary" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.tiporeferidor === null ||
                                _vm.tiporeferidor === ""
                                ? "Ninguno"
                                : _vm.tiporeferidor
                            ) +
                            " "
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "align-items-center mb-3" },
                [
                  _c("b-col", [
                    _c("img", {
                      staticStyle: { opacity: ".45" },
                      attrs: {
                        src: require("../assets/images/international.png"),
                        width: "30"
                      }
                    }),
                    _c("b", { staticClass: "pl-2" }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.internacional === null ||
                                _vm.internacional === ""
                                ? ""
                                : _vm.internacional
                            ) +
                            " "
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "pl-", attrs: { cols: "3" } },
            [
              _c(
                "b-row",
                { staticClass: "align-items-center mb-2" },
                [
                  _c("b-col", [
                    _c("img", {
                      attrs: {
                        src: require("../assets/images/enfermera-icon.png"),
                        width: "30"
                      }
                    }),
                    _c("b", { staticClass: "pl-2" }, [
                      _vm._v(_vm._s(_vm.ownerID))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "align-items-center mb-2" },
                [
                  _c("b-col", [
                    _c("img", {
                      attrs: {
                        src: require("../assets/images/doctor-icon.png"),
                        width: "30",
                        alt: ""
                      }
                    }),
                    _c("b", { staticClass: "pl-2" }, [
                      _vm._v(_vm._s(_vm.doc_info))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "align-items-center mb-2" },
                [
                  _c("b-col", [
                    _c("img", {
                      attrs: {
                        src: require("../assets/images/enfermera-icon2.png"),
                        width: "30"
                      }
                    }),
                    _c("b", { staticClass: "pl-2" }, [
                      _vm._v(_vm._s(_vm.med_assistance))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "1", "align-self": "center", align: "end" } },
            [_c("MenuUser")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }