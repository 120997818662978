<template>
  <div style="display: flex; justify-content: center">
    <b-alert v-if="owner === '0'" class="check__owner" show dismissible>
      <h1>Patient has no contact owner.</h1>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'Owner',
  data() {
    return {
      owner: sessionStorage.getItem('flagOwner'),
    };
  },
};
</script>

<style>
.check__owner {
  position: fixed;
  top: 5px;
  width: 40%;
  z-index: 100;
  background-color: rgb(245, 133, 133);
}

.check__owner h1 {
  font-size: 18px;
  align-self: center;
  color: rgb(36, 35, 35);
}
</style>
