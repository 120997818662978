var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "flex", "justify-content": "center" } },
    [
      _vm.owner === "0"
        ? _c(
            "b-alert",
            {
              staticClass: "check__owner",
              attrs: { show: "", dismissible: "" }
            },
            [_c("h1", [_vm._v("Patient has no contact owner.")])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }